<template>
  <main class="home">
    <div class="header">
      <div class="invoices-header">
        <div class="title">
          <h1 class="title-name">Pilih Store</h1>
          <p class="title-total">Total Store: {{ store.length }}</p>
        </div>
      </div>
    </div>
    <div class="invoices-container">
      <pulse-loader class="loader" color="#fff" v-if="isLoading" />
      <template v-else>
        <template v-if="store.length">
          <div v-for="(item, i) in store" :key="i" class="invoice-item" @click="$router.push({ path: '/invoice', query: { store: item.id } })">
            <h3>{{ item.nama }}</h3>
            <svg
              color="hsl(252, 94%, 67%)"
              viewBox="0 0 1024 1024"
              style="fill: currentcolor; width: 10px; height: 10px"
            >
              <path
                d="M328.4 30l-144.8 144.8 337.2 337.2-337.2 337.2 144.8 144.8 482-482z"
              ></path>
            </svg>
          </div>
        </template>
        <template v-else>
          <div class="no-data">
            <h2>Tidak ada data</h2>
          </div>
        </template>
        <div class="invoice-item" @click="$router.push({ path: '/invoice' })">
          <h3>Semua Store</h3>
          <svg
            color="hsl(252, 94%, 67%)"
            viewBox="0 0 1024 1024"
            style="fill: currentcolor; width: 10px; height: 10px"
          >
            <path
              d="M328.4 30l-144.8 144.8 337.2 337.2-337.2 337.2 144.8 144.8 482-482z"
            ></path>
          </svg>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import Axios from "axios";
import { mapMutations } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "Home",
  props: {},
  components: {
    PulseLoader,
  },
  data() {
    return {
      isLoading: false,
      store: []
    };
  },
  methods: {
    ...mapMutations(["SET_STORE_LIST"]),
    getStore() {
      this.isLoading = true;
      Axios.get("/store?all=true")
        .then((r) => {
          this.store = r.data;
          this.SET_STORE_LIST(r.data);
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    }
  },
  created() {
    this.getStore();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader {
  text-align: center;
  top: 50%;
  position: absolute;
  left: 50%;
}
.home {
  padding: 50px 150px 50px 220px;
  height: 100vh;
  width: 100%;
}
.no-data {
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.invoices-container {
  height: 70%;
  overflow-y: scroll;
  padding-right: 10px;
}
.invoice-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 20px;
  border: 1px solid #1e2139;
  border-radius: 8px;
  background-color: #1e2139;
  color: white;
  transition: border 350ms ease-in-out;
  cursor: pointer;
  margin-bottom: 20px;
}
.invoice-item:hover {
  border: 1px solid #7b5cfa;
}
.header {
  margin-bottom: 30px;
}
.invoices-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 20px;
}
.title {
  flex-basis: 50%;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #141624;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #252946;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  .home {
    padding: 100px 120px 20px 120px;
  }
  .header {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  .home {
    padding: 100px 20px 20px 20px;
  }
}
</style>
